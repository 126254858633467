document.addEventListener( "DOMContentLoaded", () => {
	const menu = new Mmenu( ".mobile-navigation-menu", {
		extensions: [
			"position-back",
			"position-right",
		],
		navbars: [],
		screenReader: {
			aria: true,
			text: true,
		},
	});

	$(".menu-button").click(function() {
		if ($(".mobile-navigation-menu").hasClass("mm-opened")) {
			menu.API.close()
		}
		else {
			menu.API.open()
		}
	});

	window.addEventListener( "scroll", () => {
		document.querySelector( "body" ).classList.toggle( "translucent-bg", window.scrollY > 50 )
	});

	/* Apply FitVids to YouTube videos */
	if ($("body").innerWidth() <= 991 ) {
		$(".home-content").fitVids({ ignore: ".nofit" });
		$(".inside-content-section").fitVids({ ignore: ".nofit" });
	}
})
